import commonAction from 'actions/commonAction';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import PaylocityApi from 'api/generated/PaylocityApi';

export const DISCONNECT_PAYLOCITY = 'DISCONNECT_PAYLOCITY';

export const disconnectPaylocity = (teamId: string) =>
    commonAction(async () => new PaylocityApi().disconnect(teamId), DISCONNECT_PAYLOCITY, {
        toastErrorMessage: 'Failed disconnecting Paylocity',
        toastSuccessMessage: 'Successfully disconnected Paylocity',
        unconditionalHook: ({ dispatch }) => dispatch(getTeamProfile(teamId)),
    });
