import commonAction from 'actions/commonAction';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import { IPaylocityCredentialDto, PaylocityCredentialDto } from 'api/generated/models';
import PaylocityApi from 'api/generated/PaylocityApi';

export const CONFIGURE_PAYLOCITY_CREDENTIALS = 'CONFIGURE_PAYLOCITY_CREDENTIALS';

export const configurePaylocityCredentials = (
    teamId: string,
    credentials: IPaylocityCredentialDto
) =>
    commonAction(
        async () =>
            new PaylocityApi().configureConnection(teamId, new PaylocityCredentialDto(credentials)),
        CONFIGURE_PAYLOCITY_CREDENTIALS,
        {
            toastErrorMessage: (response) =>
                `Failed to configure connection. ${response.error.response?.data.errorMessage}`,
            toastSuccessMessage: 'Successfully set Paylocity credentials',
            unconditionalHook: ({ dispatch }) => dispatch(getTeamProfile(teamId)),
        }
    );
