import commonAction from 'actions/commonAction';
import IchraClassApi from 'api/generated/IchraClassApi';

export const IMPORT_AGE_BANDED_TABLE_ACTION = 'IMPORT_AGE_BANDED_TABLE';

export const importAgeBandedTable = (ageBandedTableFile: File | undefined) =>
    commonAction(
        async () =>
            new IchraClassApi().importAgeBandedTable(null, {
                data: ageBandedTableFile,
                fileName: ageBandedTableFile?.name as string,
            }),
        IMPORT_AGE_BANDED_TABLE_ACTION,
        {
            toastErrorMessage: (response) => {
                let responseString = `Failed to import Age-Banded Table file ${ageBandedTableFile?.name}.\n`;
                response.error?.response?.data.errors?.forEach((e: string) => {
                    responseString += `\n · ${e}`;
                });
                return responseString;
            },
            toastSuccessMessage: `Successfully imported Age-Banded Table file ${ageBandedTableFile?.name}.`,
        }
    );
